import './styles.scss';

import { Form } from 'antd';
import B2BSelectCustomer from 'components/B2BSelectCustomer';
import B2bSelectSalesOrg from 'components/B2bUserForm/B2bSelectSalesOrg';
import CustomButton from 'components/CustomButton';
import { CONFIG_LEVELS, DEFAULT_CONFIG_SCOPE } from 'libs/constants/configs';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ScopeSelection = (props) => {
  const { onSelectScope, topLevel = CONFIG_LEVELS.GLOBAL } = props;

  const { t } = useTranslation();

  const initialValues = {
    countryId: '',
    customerNumber: '',
  };

  const [form] = Form.useForm();

  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleCountryChanged = useCallback(
    (countryId) => {
      if (isMounted.current) {
        form.setFieldsValue({
          customerNumber: '',
        });
        console.log('countryId', countryId);

        onSelectScope({
          level: countryId ? CONFIG_LEVELS.COUNTRY : CONFIG_LEVELS.GLOBAL,
          countryId,
          customerNumber: '',
        });
      }
    },
    [form, onSelectScope]
  );

  const handleCustomerChanged = useCallback(
    (customerNumber) => {
      const countryId = form.getFieldValue('countryId');
      onSelectScope({
        level: customerNumber ? CONFIG_LEVELS.CUSTOMER : CONFIG_LEVELS.COUNTRY,
        countryId,
        customerNumber,
      });
    },
    [form, onSelectScope]
  );

  const handleClickButton = useCallback(() => {
    form.setFieldsValue({
      countryId: '',
      customerNumber: '',
    });
    onSelectScope(DEFAULT_CONFIG_SCOPE);
  }, [form, onSelectScope]);

  const globalScope = useMemo(() => {
    return (
      <Form.Item
        label={t('scopeBlock.firstLevel')}
        className="custom-form-item mr-5"
      >
        <CustomButton buttonSize="small" onClick={handleClickButton}>
          {t('scopeBlock.host')}
        </CustomButton>
      </Form.Item>
    );
  }, [t, handleClickButton]);

  const countryScope = useMemo(
    () => (
      <Form.Item
        label={t('form.label.salesOrg')}
        name="countryId"
        className="custom-form-item mr-5 scope-selection__select-box"
      >
        <B2bSelectSalesOrg
          placeHolder={t('form.label.salesOrg')}
          onChange={handleCountryChanged}
        />
      </Form.Item>
    ),
    [t, handleCountryChanged]
  );

  const customerScope = useMemo(
    () => (
      <Form.Item dependencies={['countryId']}>
        {({ getFieldValue }) => {
          const country = getFieldValue('countryId');

          return (
            <Form.Item
              label={t('form.label.customer')}
              name="customerNumber"
              className="custom-form-item scope-selection__select-box"
            >
              <B2BSelectCustomer
                country={country}
                onChange={handleCustomerChanged}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    ),
    [t, handleCustomerChanged]
  );

  const renderScopeLevels = useMemo(() => {
    const SCOPE_LEVEL_PRIORITY = [
      {
        level: CONFIG_LEVELS.GLOBAL,
        scope: globalScope,
      },
      {
        level: CONFIG_LEVELS.COUNTRY,
        scope: countryScope,
      },
      {
        level: CONFIG_LEVELS.CUSTOMER,
        scope: customerScope,
      },
    ];

    const topLevelPriority = SCOPE_LEVEL_PRIORITY.findIndex(
      (scope) => scope?.level === topLevel
    );

    const availableScopes = SCOPE_LEVEL_PRIORITY.filter(
      (_, index) => index >= topLevelPriority
    );

    return (
      <>
        {availableScopes.map((scope) => (
          <div key={scope?.level}>{scope?.scope}</div>
        ))}
      </>
    );
  }, [topLevel, globalScope, countryScope, customerScope]);

  return (
    <Form
      className="scope-selection__form"
      layout="vertical"
      form={form}
      initialValues={{ ...initialValues }}
    >
      <div className="scope-selection__wrapper">{renderScopeLevels}</div>
    </Form>
  );
};

ScopeSelection.propTypes = {
  onSelectScope: PropTypes.func.isRequired,
  topLevel: PropTypes.string,
};

ScopeSelection.defaultProps = {
  topLevel: CONFIG_LEVELS.GLOBAL,
};

export default ScopeSelection;
